import { Currency, Product } from '@menesko/models-harmonia';
import { useMemo } from 'react';

import { useAppRouter } from './useAppRouter';
import { useProductsQuery } from './useProductsQuery';

export const useProducts = () => {
  const { data } = useProductsQuery();
  const { query } = useAppRouter();
  const products = useMemo(() => data?.products || [], [data]);

  const findProductById = (productId: string) => {
    return products.find(({ id }) => id === productId);
  };

  const findProductPrice = (products?: Product, currency?: Currency) => {
    return products?.prices?.find((price) => price.currency === currency);
  };

  const getMainProducts = () => {
    if (query.coupon === 'CALM10') {
      return {
        1: {
          main: findProductById('25'),
          sub: findProductById('26'),
        },
        3: {
          main: findProductById('24'),
          sub: findProductById('21'),
        },
        6: {
          main: findProductById('23'),
          sub: findProductById('20'),
        },
      };
    }

    return {
      1: {
        main: findProductById('18'),
        sub: findProductById('19'),
      },
      3: {
        main: findProductById('30'),
        sub: findProductById('28'),
      },
      6: {
        main: findProductById('29'),
        sub: findProductById('27'),
      },
    };
  };

  return {
    products,
    mainProducts: getMainProducts(),
    findProductById,
    findProductPrice,
  };
};
